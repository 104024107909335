<template>
  <div class="">
    <global-header></global-header>
    <search-bar></search-bar>
    <router-view></router-view>
    <global-footer></global-footer>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: 'commonLayout',
  components: {
    GlobalHeader: () => import('@CMP/common/GlobalHeader'),
    SearchBar: () => import('@PAGE/home/HomeSearchBar'),
    GlobalFooter: () => import('@CMP/common/GlobalFooter')
  },
  data() {

    }
}
</script>

<style scoped>
</style>
